import { Dispatch, SetStateAction, createContext, useState } from "react";
import { IProfileData } from "@/pages/profile/interfaces";

const ProfileContext = createContext<{ profileData?: IProfileData; setProfileData: Dispatch<SetStateAction<IProfileData>> }>({} as any);

const ProfileProvider = (props: { children: any; }) => {
  const [profileData, setProfileData] = useState<IProfileData>({ studentId: null });

  return <ProfileContext.Provider value={{
    profileData,
    setProfileData,
  }}>
    {props.children}
  </ProfileContext.Provider>
};

const ProviderConsumer = ProfileContext.Consumer;

export { ProfileContext, ProfileProvider, ProviderConsumer };
import dynamic from "next/dynamic";
import { inter } from "@/helpers/font";

const Header = dynamic(() => import("@/components/Header"));

const NoFooterLayout = (props: { children: React.ReactNode }) => {
  const pageProps = (props.children as any)?.[0]?.props?.children?.props;
  const breadcrumbs = pageProps?.breadcrumbs;

  return (
    <div id="base-layout" className={`${inter.variable} font-sans`}>
      <Header breadcrumbs={breadcrumbs} />
      {props.children}
    </div>
  );
};

export default NoFooterLayout;

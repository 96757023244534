import { inter } from "@/helpers/font";

const NoHeaderFooterLayout = (props: { children: React.ReactNode }) => {
  return (
    <div id="base-layout" className={`${inter.variable} font-sans`}>
      {props.children}
    </div>
  );
};

export default NoHeaderFooterLayout;

import FaHouseChimney from "../SVG/Fontawesome/FaHouseChimney";
import { useRouter } from "next/router";

const HomeIcon = () => {
  const router = useRouter();
  const pathName = router.asPath;

  return <FaHouseChimney className={`text-2xl m-auto ${(pathName === "/" || pathName === "/home") ? "text-accent" : "text-gray-500"}`} />
}

export default HomeIcon;

const DiscountBadge = (props: { text?: string, discount?: number, discount_percent?: string, base_price?: string, display_price?: string, className: string }) => {

  if (props.text) {
    // Display custom text if the "text" prop is provided
    return <span className={props.className}>{props.text}</span>;
  }
    
  if (props.discount_percent) {
    // Display the discount percentage if "discount_percent" is provided
    return <span className={props.className}>Save {props.discount_percent}</span>;
  }
    
  if (props.discount) {
    // Calculate and display the discount percentage based on "discount" and "base_price"
    const discountPercentage = ((props.discount / Number(props.base_price)) * 100).toFixed();
    return <span className={props.className}>{`Save ${discountPercentage}%`}</span>;
  }
    
  if (props.base_price && props.display_price) {
    // Calculate and display the discount percentage based on "base_price" and "display_price"
    const discountPercentage = ((Number(props.base_price) - Number(props.display_price)) / Number(props.base_price) * 100).toFixed();
    return <span className={props.className}>{`Save ${discountPercentage}%`}</span>;
  }
    
  // Return null if no conditions match (component renders nothing)
  return null;
}

export default DiscountBadge
import LinkWrapper from "@/components/LinkWrapper";
import FaPen from "@/components/SVG/Fontawesome/FaPen";
import SunTornado from "@/components/SVG/HeroSection/Abstract/SunTornado";
import { getProfileData, getSignedUrl, updateProfileData, uploadProfileImage } from "@/helpers/api/service/profile";
import { IBoardData, IClassData, IExamData, IProfileData } from "@/pages/profile/interfaces";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Image from "next/image"
import { ProfileContext } from "@/providers/profile";
import { useSelector } from "react-redux";
import { selectUser } from "@/store/slices/auth";

const ProfileLayout = (props: { children: React.ReactNode }) => {
  const user = useSelector(selectUser);
  const profileContext = useContext(ProfileContext)
  const router = useRouter();
  const profileImageRef = useRef<any>(null);
  const {
    profileData,
    setProfileData
  } = profileContext;
  const studentClass = profileData?.["class"]?.options.filter(
    (item: IClassData) => item.selected
  )[0]?.name;

  const profileItems = [
    { value: "My Profile", id: "profile" },
    { value: "Set / Update PIN", id: "change-pin" },
    {value: "My Saved Videos", id: "saved-videos"}
  ]
  const [selectedProfileItemId, updateSelectedProfileItemId] = useState<string>();

  const handleImageChange = async () => {
    const file = profileImageRef.current.files[0];
    const type = file?.name.split(".")[1];
    if (!file?.size) return;
    if (file.size > 1024 * 1024) {
      profileImageRef.current.value = "";
      toast.error("Large file size, Please upload image less than 1 mb");
      return;
    }

    const res = await getSignedUrl(file.name);
    const { signedUrl: uploadUrl, imgName } = res.data;
    const blobImg = new Blob([file], { type: `image/${type}` });
    await uploadProfileImage(uploadUrl, blobImg);

    //update profile image
    const response = await updateProfileData({
      studentId: user.id,
      username: profileData?.name,
      studentClass,
      board: profileData?.board?.options[studentClass as string]?.filter(
        (item: IBoardData) => item.selected
      )[0]?.id,
      exam: profileData?.exam?.options[studentClass as string]
        ?.filter((item: IExamData) => item.selected)
        .map((item: IExamData) => item.id.toString()),
      school_name: profileData?.school,
      dob: profileData?.date_of_birth,
      image: imgName,
    });
    if (response.meta.code === 200) {
      getProfileData(user.id).then((res: any) => {
        if (res.data) {
          setProfileData({ ...res.data, studentId: user.id });
        }
      });
    }
  };
  const {
    studentId
  } = router.query;

  useEffect(() => {
    if (studentId) return;
    if (!user.id) {
      router.push(`/login?url=${router.pathname}`);
      return;
    }
    if (profileData?.studentId) return;
    getProfileData(user.id).then((res: any) => {
      if (!res.data) {
        toast.error("something went wrong");
        return;
      }
      setProfileData({ ...res.data, studentId: user.id })
    });
  }, [user, studentId, router.pathname]);

  useEffect(() => {
    const id = router.pathname.split("/")[1];
    const item = profileItems.find((item) => item.id === id);
    if (!item) return;
    updateSelectedProfileItemId(item.id);
  }, [router.pathname]);

  if (router.query.studentId) {
    return <>
      {props.children}
    </>
  }

  return (
    <>
      <div className="body-fluid my-0">
        <div className="flex relative justify-center">
          <SunTornado>
            <p className="h-16 sm:h-32"></p>
          </SunTornado>
          {/* image and username */}
          <div className="body-content absolute bottom-[-90px] sm:bottom-[-30px] flex flex-col sm:flex-row gap-4 w-full grow justify-between items-center px-5 self-center">
            <div className="flex relative justify-center w-32 h-32 sm:w-48 rounded-full">
              <input
                type="file"
                id="imageInput"
                accept="image/jpeg, image/jpg, image/png, image/webp"
                className="hidden"
                onChange={handleImageChange}
                ref={profileImageRef}
              />
              <Image
                src={profileData?.image || ""}
                width={32}
                height={32}
                alt="profile"
                className="w-32 h-full rounded-full object-cover m-0"
              />
              {profileData?.image && !router.query.studentId && (
                <label
                  htmlFor="imageInput"
                  className="absolute bottom-1 border-2 border-white rounded-full bg-black right-0 opacity-40 sm:right-6 lg:right-8 p-2"
                >
                  <FaPen className="h-5 fill-white cursor-pointer" />
                </label>
              )}
            </div>
            <div className="flex text-2xl sm:text-5xl items-start">
              <span className="relative sm:text-white font-semibold sm:bottom-10">
                {profileData?.name ? profileData.name : ""}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="body-content  min-h-[50vh] mt-28 sm:mt-10 ">
        {
          !router.query.studentId && <>
            <div className="flex flex-col sm:flex-row sm:gap-4">
              <section className="flex flex-wrap sm:flex-col gap-4 sm:basis-1/3 shrink-0 self-start items-start !mt-4">
                {profileItems.map((item, i) => (
                  <LinkWrapper key={i.toString()} className={`${item.id == selectedProfileItemId ? "text-accent border-b-2 border-accent" : ""} !no-underline max-w-max font-semibold`} href={`/${item.id}`}>
                    {item.value}
                  </LinkWrapper>
                ))
                }
              </section>
              <section className="mt-0 sm:basis-2/3">
                {props.children}
              </section>
            </div>
          </>
        }
      </div>
    </>
  );
};

export default ProfileLayout;

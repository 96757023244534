
import dynamic from "next/dynamic";
import { useDispatch } from "react-redux";
import { updateOffCanvasView } from "@/store/slices/offCanvas";
import FaProfileBars from "../SVG/Fontawesome/FaProfileBars";
import RightSidebar from "../Sidebar/Sidebar";
import { useRouter } from "next/router";
import { useMemo } from "react";

const Search = dynamic(() => import("./Search"));
const ThemeChangeButton = dynamic(() => import("./ThemeChangeButton"));
const Profile = dynamic(() => import("./Profile"));

const SearchHeader = () => {
  const router = useRouter();
  const searchBox = useMemo(() => {
    const isHomePage = router.asPath === "/" || router.asPath === "/home";
    if (isHomePage) return <></>;
    return <Search />;
  }, [router.asPath])

  return searchBox;
}

const RightIcons = () => {
  const dispatch = useDispatch();

  return (
    <div className="w-max flex flex-wrap content-center items-center gap-1 sm:gap-3">
      <SearchHeader />
      <Profile />
      <ThemeChangeButton />
      <button
        onClick={() => dispatch(updateOffCanvasView({ name: "HAMBURGER" }))}
        id="hamburger-btn"
        name="hamburger-btn"
        className="hamburger-btn text-accent text-xl transition duration-150 ease-in-out pr-2"
        type="button"
        data-te-offcanvas-toggle
        data-te-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        aria-haspopup="true"
      >
        <FaProfileBars className="text-xl sm:text-2xl" />
      </button>
      <RightSidebar />
    </div>
  );
}

export default RightIcons;
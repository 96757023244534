import { ReactNode, memo } from "react";
import DefaultNav from "./DefaultNav";
import { useRouter } from "next/router";
import CoursePricingNav from "./CoursePricingNav";
import { IWidget } from "@/pages/course/interfaces";

 
const BottomNav = (props:{pageProps: any}) => {
  const router = useRouter();

  switch (router.pathname) {
    case "/course/[assortmentId]":
      const pricePlan = {...(props.pageProps as { [widgetName: string]: IWidget }).data.widget_course_plan?.items?.[0], 
        variant_id: (props.pageProps as { [widgetName: string]: IWidget }).data.widget_view_plan_button.variant_id};
      return <CoursePricingNav data={pricePlan}/>
    default:
      return <DefaultNav />
  }
};

export default BottomNav;

const FaProfileBars = (props: { className?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" className={`${props.className || ""}`} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0703 13.1065C15.889 13.1065 16.6509 13.3139 17.3559 13.7289C18.0609 14.1439 18.6181 14.7087 19.0275 15.4233C19.4368 16.138 19.6415 16.9102 19.6415 17.7402V19.1925C19.6415 19.6536 19.4823 20.0455 19.1639 20.3682C18.8455 20.691 18.4589 20.8523 18.004 20.8523H5.99598C5.54113 20.8523 5.15451 20.691 4.83611 20.3682C4.51772 20.0455 4.35852 19.6536 4.35852 19.1925V17.7402C4.35852 16.9102 4.5632 16.138 4.97257 15.4233C5.38193 14.7087 5.93913 14.1439 6.64415 13.7289C7.34916 13.3139 8.11104 13.1065 8.92977 13.1065C9.22542 13.1065 9.61205 13.1871 10.0896 13.3485C10.408 13.4407 10.6696 13.5099 10.8743 13.556C11.2154 13.6252 11.5906 13.6597 12 13.6597C12.4094 13.6597 12.7846 13.6252 13.1258 13.556C13.3305 13.5099 13.592 13.4407 13.9104 13.3485C14.388 13.1871 14.7746 13.1065 15.0703 13.1065Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 11.9999C11.204 11.9999 10.4706 11.8039 9.79965 11.412C9.12874 11.0201 8.59998 10.4841 8.21336 9.80408C7.82673 9.12401 7.63342 8.38054 7.63342 7.57368C7.63342 6.76682 7.82673 6.02335 8.21336 5.34328C8.59998 4.66321 9.12874 4.12722 9.79965 3.73532C10.4706 3.34341 11.204 3.14746 12 3.14746C12.796 3.14746 13.5294 3.34341 14.2003 3.73532C14.8712 4.12722 15.4 4.66321 15.7866 5.34328C16.1732 6.02335 16.3666 6.76682 16.3666 7.57368C16.3666 8.38054 16.1732 9.12401 15.7866 9.80408C15.4 10.4841 14.8712 11.0201 14.2003 11.412C13.5294 11.8039 12.796 11.9999 12 11.9999Z" fill="currentColor" />
      <rect x="12" y="14.0009" width="10" height="10" rx="5" fill="#FFECE7" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20 17.5009H14V16.5009H20V17.5009Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20 19.5009H14V18.5009H20V19.5009Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20 21.5009H14V20.5009H20V21.5009Z" fill="currentColor" />
    </svg>

  );
}

export default FaProfileBars;
import Link from "next/link";
import { useRouter } from "next/router";
const FORCE_SSR_PAGES = ["/VideoPage", "/ExternalQuestions/[prefix]/[videoId]", "/user-asked-questions/[entityId]"];

const checkTargetHrefToSSR = (href: string) => {
  return href.startsWith("/question-") || href.startsWith("/qa-") || href.startsWith("/qna") || href.startsWith("/pcmb-questions") || href.startsWith("/user-asked-questions");
}

const LinkWrapper = (props: any) => {
  const router = useRouter();

  const forceSSR = FORCE_SSR_PAGES.includes(router.pathname) || checkTargetHrefToSSR(props.href);

  let linkElement = <Link {...props} >{props.children}</Link>;
  if (forceSSR) {
    const anchorProps = { ...props };
    delete anchorProps.locale;
    delete anchorProps.shallow;
    delete anchorProps.scroll;
    linkElement = <a {...anchorProps} >{props.children}</a>
  }
  return (
    <>
      {linkElement}
    </>
  );
};

export default LinkWrapper;

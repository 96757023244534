import { BACKEND_API, FRONTEND_HOST, MICRO_API } from "@/config/constants";
import { apiClient } from "@/helpers/request";

export const getProfileData = (studentId?: number) => {
  return apiClient(BACKEND_API, `/v2/student/${studentId}/profile`,
    {
      headers:{
        origin: FRONTEND_HOST
      }
    });
};

export const updateProfileData = (profileData: {studentId?: number, username?: string, studentClass?:string, board?: number, exam?: string[], school_name?: string, dob?: string, image?:string}) => {

  const {
    studentId, username, studentClass, board, exam, school_name, dob, image
  } = profileData;

  return apiClient(BACKEND_API, `/v2/student/${studentId}/profile`, {
    method: "POST",
    body: JSON.stringify({
      name: username,
      class: studentClass,
      board: board,
      exam,
      school: school_name,
      date_of_birth: dob?.split("-").reverse().join("-"),
      image_url: image,
    }),
  });
};

export const getSignedUrl = (fileName: string) => {
  return apiClient(MICRO_API, "/upload/signed-url-public", {
    method: "POST",
    body: JSON.stringify({
      entityType: "PROFILE_IMAGE_UPLOAD",
      fileName,
    }),
  });
};

export const uploadProfileImage = (url: string, image: any) => {
  return fetch(url, {
    method: "PUT",
    body: image,
  });
};

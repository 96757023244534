import { useRouter } from "next/router";
import FaUserSolid from "../SVG/Fontawesome/FaUserSolid";

const UserIcon = () => {
  const router = useRouter();
  const pathName = router.pathname;

  return <FaUserSolid className={`text-2xl m-auto ${(pathName === "/profile") ? "text-accent" : "text-gray-500"}`} />
}

export default UserIcon;
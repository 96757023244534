import { Fragment, useEffect, useState } from "react";
import FaHouseChimney from "../SVG/Fontawesome/FaHouseChimney";
import FaChevronRight from "../SVG/Fontawesome/FaChevronRight";
import LinkWrapper from "../LinkWrapper";
import Script from "next/script";
import { FRONTEND_HOST } from "@/config/constants";
import { useRouter } from "next/router";

const Breadcrumb = (props: { breadcrumbs: { url: string; text: string }[]; currentText: string; className?: string }) => {
  const router = useRouter();
  const currentText = props.currentText.length > 20 ? `${props.currentText.slice(0, 20)}...` : props.currentText;

  const textClassName = props.className?.includes("bg-") ? "text-white" : "text-badge hover:text-accent";
  const terminalTextClassName = props.className?.includes("bg-") ? "text-white" : "text-badge";

  const breadcrumbs = props.breadcrumbs.filter(x => x.url);

  return (
    <>
      {
        !!breadcrumbs.length && <Script type="application/ld+json" id="breadcrumb-schema" strategy="beforeInteractive">
          {
            JSON.stringify({
              "@context": "https://schema.org",
              "@id": `${FRONTEND_HOST}${router.asPath}#breadcrumb`,
              "@type": "BreadcrumbList",
              "name": "BreadcrumbList",
              "itemListElement": breadcrumbs.map((item, i) => ({
                "@type": "ListItem",
                "position": i + 1,
                "item": {
                  "@id": `${FRONTEND_HOST}${item.url}`,
                  "name": item.text,
                },
                "name": "BreadcrumbListItem",
              })),
            })
          }
        </Script>
      }
      <nav className={`text-gray-700 py-4 ${props.className || ""}`} aria-label="Breadcrumb">
        <div className="body-content not-prose mt-0">
          <section className="my-0">
            <ol id="breadcrumb" className="list-reset flex items-end p-0 space-x-1 sm:space-x-3 overflow-x-auto mt-0 text-xs lg:text-sm">
              <li>
                <a href="/" className={`hover:underline ${textClassName}`}>
                  <FaHouseChimney />
                </a>
              </li>
              <li><span className={`px-1.5 sm:px-3 ${textClassName} hover-none`}>{<FaChevronRight />}</span></li>
              {
                props.breadcrumbs.map((link, i) => {
                  const text = link.text.length > 20 ? `${link.text.slice(0, 20)}...` : link.text;
                  return <Fragment key={i.toString()}>
                    <li>
                      {
                        link.url && <LinkWrapper href={link.url} className={`hover:underline w-max block ${textClassName}`}>
                          {text}
                        </LinkWrapper>
                      }
                      {
                        !link.url && <span className={`w-max block ${textClassName}`}>
                          {link.text}
                        </span>
                      }
                    </li>
                    <li><span className={`px-1.5 sm:px-3 ${textClassName} hover-none`}>{<FaChevronRight />}</span></li>
                  </Fragment>
                })
              }
              <li className={`w-max block ${terminalTextClassName}`}>
                <span className="w-max block">
                  {currentText}
                </span>
              </li>
            </ol>
          </section>
        </div>
      </nav>
    </>
  );
}

export default Breadcrumb;
import { memo, useState } from "react";
import ImageBanner from "../CourseWidget/ImageBanner";
import FaClose from "../SVG/Fontawesome/FaClose";
import { IClosableBannerData } from "./interface";

const ClosableBanner = (props: { data: IClosableBannerData }) => {
  const [showBanner, setShowBanner] = useState<boolean>(true);

  const styleProps: any = {};
  if (props.data.width) {
    styleProps.width = `${props.data.width}px`;
  }
  if (props.data.height) {
    styleProps.height = `${props.data.height}px`;
  }

  return <div className={`${showBanner ? "block" : "hidden"} sm:hidden sticky top-0 z-[1045] max-w-full mx-auto`} style={styleProps}>
    <ImageBanner eagerLoad id="top-sticky-banner" data={props.data} className="!my-0" />
    {
      props.data.closable && <button className="absolute top-0.5 right-0.5" id="closable-banner" name="closable-banner" onClick={() => setShowBanner(false)}>
        <FaClose className="w-3 !h-3 !align-top" />
      </button>
    }
  </div>
}

export default memo(ClosableBanner);
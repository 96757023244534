import LinkWrapper from "../LinkWrapper";
import HomeIcon from "./HomeIcon";
import AskDoubt from "../PageOverlay/icons/AskDoubt";
import UserIcon from "./UserIcon";
import { memo } from "react";

const DefaultNav = () => {
  return (
    <div className="bg-secondary-light shadow-2xl fixed bottom-0 left-0 right-0 sm:hidden z-50 drop-shadow-lg h-16" style={{
      borderRadius: "45% 45% 0% 0% / 35% 35% 0% 0%",
    }}>
      <ul className="grid grid-cols-4 h-full items-end px-12 py-2">
        <li>
          <LinkWrapper href="/" className="bottom-navbar-home text-center flex flex-col gap-1">
            <HomeIcon />
            <span className="w-full text-xs whitespace-nowrap">
              Home
            </span>
          </LinkWrapper>
        </li>
        <li className="justify-self-center col-span-2">
          <span className="bottom-navbar-ask text-center flex flex-col gap-1 relative">
            <div className="absolute -top-12">
              <AskDoubt />
            </div>
            <div className="absolute -bottom-1 -left-7 text-xs whitespace-nowrap">
              Ask Doubt
            </div>
          </span>
        </li>
        <li>
          <LinkWrapper href="/profile" className="bottom-navbar-profile text-center flex flex-col gap-1">
            <UserIcon />
            <span className="w-full text-xs">Profile</span>
          </LinkWrapper>
        </li>
      </ul>
    </div>
  )
}

export default memo(DefaultNav)
import { DEEPLINK_PREFIX } from "@/config/constants";
import FaArrowRight from "../SVG/Fontawesome/FaArrowRight";

const AppInstallBtn = () => {
  return (
    <button className="lg:hidden bg-accent font-semibold uppercase rounded-full px-2 text-xs text-white fill-primary">
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        href={`${DEEPLINK_PREFIX}/_BQ8daeDfG0km`}
        target="_blank"
        className="w-max py-2 normal-case flex items-center gap-2"
      >
        <span>Ask Doubt on App</span>
        <span><FaArrowRight className="fill-white"/></span>
      </a>
    </button>
  );
}

export default AppInstallBtn